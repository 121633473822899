import React from "react";

import "./Footer.css";

export default function Footer({ children }) {
  return (
    <div>
      <div className="phantomStyle" />
      <div className="footer">{children}</div>
    </div>
  );
}
