import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles";

let theme = createMuiTheme();
theme.typography.h1 = {
  fontSize: "5.0rem",
  "@media (max-width:600px)": {
    fontSize: "3.5rem",
  },
};

theme.typography.h2 = {
  fontSize: "4.0rem",
  "@media (max-width:600px)": {
    fontSize: "2.5rem",
  },
};

// theme = responsiveFontSizes(theme);

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
