import React from "react";
import { Switch, Route } from "react-router-dom";

import LandingPage from "../components/LandingPage/LandingPage";
import About from "../components/About/About";
import Contact from "../components/Contact/Contact";
import Gallery from "../components/Gallery/Gallery";
import Impressum from "../components/Impressum/Impressum";
import Privacy from "../components/Privacy/Privacy";

const ContentRoutes = () => (
  <Switch>
    <Route exact path="/" component={LandingPage} />
    <Route path="/about" component={About} />
    <Route path="/contact" component={Contact} />
    <Route path="/gallery" component={Gallery} />
    <Route path="/impressum" component={Impressum} />
    <Route path="/privacy" component={Privacy} />
  </Switch>
);

export default ContentRoutes;
