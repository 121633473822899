import { v4 as uuid } from "uuid";

const data = [
  {
    id: uuid(),
    img: "images/photos/Bad4_1.jpg",
    title: "Bad4_1",
    author: "Andreas Kiese",
    category: "bath",
    cols: 1,
  },
  {
    id: uuid(),
    img: "images/photos/Bad4_2.jpg",
    title: "Bad4_2",
    author: "Andreas Kiese",
    category: "bath",
    cols: 2,
  },
  {
    id: uuid(),
    img: "images/photos/Bad4_3.jpg",
    title: "Bad4_3",
    author: "Andreas Kiese",
    category: "bath",
    cols: 1,
  },
  {
    id: uuid(),
    img: "images/photos/Bad4_4.jpg",
    title: "Bad4_4",
    author: "Andreas Kiese",
    category: "bath",
    cols: 2,
  },
  {
    id: uuid(),
    img: "images/photos/Bad4_5.jpg",
    title: "Bad4_5",
    author: "Andreas Kiese",
    category: "bath",
    cols: 1,
  },
  {
    id: uuid(),
    img: "images/photos/Bad4_6.jpg",
    title: "Bad4_6",
    author: "Andreas Kiese",
    category: "bath",
    cols: 1,
  },
  {
    id: uuid(),
    img: "images/photos/Bad4_7.jpg",
    title: "Bad4_7",
    author: "Andreas Kiese",
    category: "bath",
    cols: 2,
  },
  {
    id: uuid(),
    img: "images/photos/Bad4_8.jpg",
    title: "Bad4_8",
    author: "Andreas Kiese",
    category: "bath",
    cols: 1,
  },
  {
    id: uuid(),
    img: "images/photos/Bad4_9.jpg",
    title: "Bad4_9",
    author: "Andreas Kiese",
    category: "bath",
    cols: 1,
  },
  {
    id: uuid(),
    img: "images/photos/Bad4_10.jpg",
    title: "Bad4_10",
    author: "Andreas Kiese",
    category: "bath",
    cols: 1,
  },
  {
    id: uuid(),
    img: "images/photos/Bad1_1.jpg",
    title: "Bad1_1",
    author: "Andreas Kiese",
    category: "bath",
    cols: 1,
  },
  {
    id: uuid(),
    img: "images/photos/Bad1_2.jpg",
    title: "Bad1_2",
    author: "Andreas Kiese",
    category: "bath",
    cols: 2,
  },
  {
    id: uuid(),
    img: "images/photos/Bad1_3.jpg",
    title: "Bad1_3",
    author: "Andreas Kiese",
    category: "bath",
    cols: 2,
  },
  {
    id: uuid(),
    img: "images/photos/Bad1_4.jpg",
    title: "Bad1_4",
    author: "Andreas Kiese",
    category: "bath",
    cols: 1,
  },
  {
    id: uuid(),
    img: "images/photos/Bad1_5.jpg",
    title: "Bad1_5",
    author: "Andreas Kiese",
    category: "bath",
    cols: 2,
  },
  {
    id: uuid(),
    img: "images/photos/Bad1_6.jpg",
    title: "Bad1_6",
    author: "Andreas Kiese",
    category: "bath",
    cols: 1,
  },
  {
    id: uuid(),
    img: "images/photos/Bad2_1.jpg",
    title: "Bad2_1",
    author: "Andreas Kiese",
    category: "bath",
    cols: 1,
  },
  {
    id: uuid(),
    img: "images/photos/Bad2_2.jpg",
    title: "Bad2_2",
    author: "Andreas Kiese",
    category: "bath",
    cols: 2,
  },
  {
    id: uuid(),
    img: "images/photos/Bad2_3.jpg",
    title: "Bad2_3",
    author: "Andreas Kiese",
    category: "bath",
    cols: 2,
  },
  {
    id: uuid(),
    img: "images/photos/Bad2_4.jpg",
    title: "Bad2_4",
    author: "Andreas Kiese",
    category: "bath",
    cols: 1,
  },
  {
    id: uuid(),
    img: "images/photos/Bad2_5.jpg",
    title: "Bad2_5",
    author: "Andreas Kiese",
    category: "bath",
    cols: 1,
  },
  {
    id: uuid(),
    img: "images/photos/Bad2_7.jpg",
    title: "Bad2_7",
    author: "Andreas Kiese",
    category: "bath",
    cols: 2,
  },
  {
    id: uuid(),
    img: "images/photos/Bad3_1.jpg",
    title: "Bad3_1",
    author: "Andreas Kiese",
    category: "bath",
    cols: 1,
  },
  {
    id: uuid(),
    img: "images/photos/Bad3_2.jpg",
    title: "Bad3_2",
    author: "Andreas Kiese",
    category: "bath",
    cols: 1,
  },
  {
    id: uuid(),
    img: "images/photos/Bad3_3.jpg",
    title: "Bad3_3",
    author: "Andreas Kiese",
    category: "bath",
    cols: 1,
  },
  {
    id: uuid(),
    img: "images/photos/Bad3_4.jpg",
    title: "Bad3_4",
    author: "Andreas Kiese",
    category: "bath",
    cols: 1,
  },
  {
    id: uuid(),
    img: "images/photos/Bad3_5.jpg",
    title: "Bad3_5",
    author: "Andreas Kiese",
    category: "bath",
    cols: 2,
  },
  {
    id: uuid(),
    img: "images/photos/Bad3_6.jpg",
    title: "Bad3_6",
    author: "Andreas Kiese",
    category: "bath",
    cols: 1,
  },
  {
    id: uuid(),
    img: "images/photos/Kamin1_1.jpg",
    title: "Kamin_1",
    author: "Andreas Kiese",
    category: "fireplace",
    cols: 1,
  },
  {
    id: uuid(),
    img: "images/photos/Kamin1_2.jpg",
    title: "Kamin_2",
    author: "Andreas Kiese",
    category: "fireplace",
    cols: 2,
  },
  {
    id: uuid(),
    img: "images/photos/Heizungsraum1_1.jpg",
    title: "Heizungsraum1_2",
    author: "Andreas Kiese",
    category: "heating_technology",
    cols: 1,
  },
  {
    id: uuid(),
    img: "images/photos/Heizungsraum1_2.jpg",
    title: "Heizungsraum1_2",
    author: "Andreas Kiese",
    category: "heating_technology",
    cols: 2,
  },
  {
    id: uuid(),
    img: "images/photos/Heizungsraum1_3.jpg",
    title: "Heizungsraum1_3",
    author: "Andreas Kiese",
    category: "heating_technology",
    cols: 3,
  },
  {
    id: uuid(),
    img: "images/photos/Heizungsraum1_4.jpg",
    title: "Heizungsraum1_4",
    author: "Andreas Kiese",
    category: "heating_technology",
    cols: 3,
  },
];

export default data;
