import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import "./About.css";

export default function About() {
  return (
    <Grid className="aboutContainer" container>
      <Grid className="aboutContainer__leftSide" item md={7}>
        <Grid container>
          <Grid item>
            <img height="190" src="images/photos/Kiese_1.jpg" alt="avatar" />
          </Grid>
          <Grid className="aboutContainer__leftSide__description" item>
            <Typography variant="h4">Andreas Kiese</Typography>
            <Typography variant="subtitle1">
              Gas- / Wasserinstallateur seit über 35 Jahren
            </Typography>
          </Grid>
        </Grid>
        <hr />
        <div className="aboutContainer__leftSide__text">
          <Typography variant="body1">
            Die Firma Kiese-Service wurde im Jahr 2016 mit dem Ziel gegründet
            qualitativ hochwertige und gründliche Handwerkstätigkeiten in den
            Bereichen Sanitär und Bad, Heizungstechnik, Kaminöfen und Facility
            Management aus einer Hand anbieten zu können. Ich habe
            jahrzehntelange Erfahrung auf diesen Gebieten sammeln können und
            mich zu dem Schritt entschieden meine Leidenschaft in der Welt der
            Selbstständigkeit auszuüben.
          </Typography>
          <br />
          <Typography variant="body1">
            Mir ist es wichtig ein gutes und kommunikatives Verhältnis zum
            Kunden zu haben, um dessen Vorstellungen möglichst exakt in die
            Realität umsetzen zu können. Größere Projekte wie Badsanierungen
            werden von der Auswahl der Fliesen bis hin zum Einbau der Dusche
            komplett von mir selbst geplant und auch ausgeführt ohne
            Kommunikationswege über Dritte gehen zu müssen.
          </Typography>
        </div>
      </Grid>
      <Grid className="aboutContainer__rightSide" item md={5}>
        <div className="aboutContainer__rightSide__companyHistory">
          <div>
            <Typography variant="h5">Sanitär</Typography>
            <hr />
            <Typography variant="body1">
              Badsanierung | Reparaturen | Rohrinstallation
            </Typography>
          </div>
          <div className="aboutContainer__rightSide__companyHistory__services">
            <Typography variant="h5">Heizung</Typography>
            <hr />
            <Typography variant="body1">
              Wartung | Erneuerung | Heizkörpertausch
            </Typography>
          </div>
          <div className="aboutContainer__rightSide__companyHistory__services">
            <Typography variant="h5">Kamin</Typography>
            <hr />
            <Typography variant="body1">
              Gerco-Ersatzteile | Gerco-Instandhaltung | Gerco-Support
            </Typography>
          </div>
          <div className="aboutContainer__rightSide__companyHistory__services">
            <Typography variant="h5">Facility</Typography>
            <hr />
            <Typography variant="body1">
              Mauer-, Putz- und Fliesenarbeiten
            </Typography>
            <Typography variant="body1">Dachrinnenreparaturen</Typography>
          </div>
          <div className="aboutContainer__rightSide__companyHistory__more">
            <Typography variant="body1">
              Weitere Tätigkeiten auf Anfrage
            </Typography>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
