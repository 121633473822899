import React from "react";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";

import "./Contact.css";

export default function Contact() {
  return (
    <div className="contactPage">
      <Grid className="contactPage__Grid" container>
        <Grid className="contactPage__leftGrid" item>
          <img src="images/logo.png" alt="logo" width="500" />
        </Grid>
        <Grid className="contactPage__rightGrid" item>
          <Typography variant="h3">Kontakt</Typography>
          <hr />
          <Typography variant="subtitle1">Andreas Kiese</Typography>
          <Typography variant="subtitle1">Rosenweg 5</Typography>
          <Typography variant="subtitle1">48336 Sassenberg</Typography>
          <br />
          <Typography variant="subtitle1">0176 638 122 60</Typography>
          <Typography variant="subtitle1">
            <a
              href="mailto:info@kiese-service.de"
              rel="noopener noreferrer"
              target="_blank"
            >
              info@kiese-service.de
            </a>
          </Typography>
          <Typography variant="subtitle1">
            <a
              href="http://www.kiese-service.de"
              rel="noopener noreferrer"
              target="_blank"
            >
              www.kiese-service.de
            </a>
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}
