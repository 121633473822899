import React from "react";
import Typography from "@material-ui/core/Typography";

import "./Impressum.css";

export default function Impressum() {
  return (
    <div className="impressum">
      <Typography variant="h2">Impressum</Typography>
      <br />
      <Typography variant="subtitle1">Andreas Kiese</Typography>
      <Typography variant="subtitle1">Kiese Service</Typography>
      <Typography variant="subtitle1">Rosenweg 5</Typography>
      <Typography variant="subtitle1">48336 Sassenberg</Typography>
      <br />
      <Typography variant="subtitle1">Telefon: 017663812260</Typography>
      <Typography variant="subtitle1">
        E-Mail:
        <a
          href="mailto:info@kiese-service.de"
          rel="noopener noreferrer"
          target="_blank"
        >
          info@kiese-service.de
        </a>
      </Typography>
      <br />
      <Typography variant="subtitle1">
        <b>Aufsichtsbehörde:</b> Handwerkskammer Münster
      </Typography>
      <Typography variant="subtitle1">
        <b>Berufshaftpflichtversicherung:</b> VHV Versicherungen
      </Typography>
      <Typography variant="subtitle1">VHV-Platz 1</Typography>
      <Typography variant="subtitle1">30177 Hannover</Typography>
      <Typography variant="subtitle1">Geltungsbereich: Weltweit</Typography>
    </div>
  );
}
