import React, { useState } from "react";
import "./App.css";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Link } from "react-router-dom";

import ContentRoutes from "./components/ContentRoutes";
import Footer from "./components/Footer/Footer";

export default function App() {
  const [state, setState] = useState({
    drawer: false,
  });

  const toggleDrawer = (toggle) => (event) => {
    setState({ drawer: toggle });
  };

  return (
    <>
      <CssBaseline />
      <div className="main">
        <AppBar className="app-bar" position="fixed">
          <Toolbar>
            <IconButton
              edge="start"
              className="menuButton"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(!state.drawer)}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" className="title">
              <Link style={{ textDecoration: "none", color: "white" }} to="/">
                Kiese Service
              </Link>
            </Typography>
            {/* <div className="title">
              <IconButton component={Link} to="/">
                <img src="images/logo.jpg" alt="logo" width="80" />
              </IconButton>
            </div> */}
            <List
              className="appbar-list"
              style={{
                textDecoration: "none",
                color: "white",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <ListItem button component={Link} to="/about">
                <ListItemText primary="Über uns" />
              </ListItem>
              <ListItem button component={Link} to="/gallery">
                <ListItemText primary="Projekte" />
              </ListItem>
              <ListItem button component={Link} to="/contact">
                <ListItemText primary="Kontakt" />
              </ListItem>
            </List>
          </Toolbar>
        </AppBar>
        <Drawer
          style={{ width: "150px" }}
          anchor="left"
          open={state.drawer}
          onClose={toggleDrawer(false)}
        >
          <div
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
            style={{ width: "250px" }}
          >
            <List style={{ textDecoration: "none", color: "white" }}>
              <ListItem button component={Link} to="/about">
                <ListItemText style={{ color: "black" }} primary="Über uns" />
              </ListItem>
              <ListItem button component={Link} to="/gallery">
                <ListItemText style={{ color: "black" }} primary="Projekte" />
              </ListItem>
              <ListItem button component={Link} to="/contact">
                <ListItemText style={{ color: "black" }} primary="Kontakt" />
              </ListItem>
            </List>
          </div>
        </Drawer>
        <ContentRoutes />
        <Footer>
          <Typography variant="subtitle1">
            <Link
              style={{
                textDecoration: "none",
                color: "white",
                padding: "10px",
              }}
              to="/impressum"
            >
              Impressum
            </Link>
            <Link
              style={{
                textDecoration: "none",
                color: "white",
                padding: "10px",
              }}
              to="/privacy"
            >
              Datenschutz
            </Link>
          </Typography>
        </Footer>
      </div>
    </>
  );
}
