import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import "./LandingPage.css";

export default function LandingPage() {
  return (
    <div className="landing-page">
      <Grid container alignConent="center">
        <Grid item xs={12}>
          <div className="landing-page__header">
            <Typography variant="h1">Kiese Service</Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="banner-text">
            <Typography variant="h2">Handwerk in Perfektion</Typography>
            <hr />
            <Typography className="banner-text__abilities" variant="subtitle1">
              Sanitär | Heizung | Kamin | Facility
            </Typography>
            <div className="banner-icons">
              <i className="fas fa-faucet" aria-hidden="true" />
              <i className="fas fa-x-ray" aria-hidden="true" />
              <i className="fas fa-fire" aria-hidden="true" />
              <i className="fas fa-home" aria-hidden="true" />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
