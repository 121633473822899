import React from "react";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import Typography from "@material-ui/core/Typography";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";

import data from "../../gallery_data";
import "./Gallery.css";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));

function Gallery(props) {
  let cellHeight;

  if (isWidthUp("md", props.width)) {
    cellHeight = 390;
  } else if (isWidthUp("sm", props.width)) {
    cellHeight = 190;
  } else {
    cellHeight = 90;
  }

  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "6em",
        }}
        className={classes.root}
      >
        <Tabs value={value} onChange={handleChange} aria-label="Item tabs">
          <Tab label="Bad" {...a11yProps(0)} />
          <Tab label="Kamin" {...a11yProps(1)} />
          <Tab label="Heizungstechnik" {...a11yProps(2)} />
        </Tabs>
      </div>
      <div>
        <TabPanel value={value} index={0}>
          <GridList cellHeight={cellHeight} cols={1} spacing={8}>
            {data
              .filter((image) => image.category === "bath")
              .map((tile) => (
                <GridListTile rows={2} key={tile.id} cols={1}>
                  <img src={tile.img} alt={tile.title} />
                </GridListTile>
              ))}
          </GridList>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <GridList cellHeight={cellHeight} cols={1} spacing={8}>
            {data
              .filter((image) => image.category === "fireplace")
              .map((tile) => (
                <GridListTile rows={2} key={tile.id} cols={1}>
                  <img src={tile.img} alt={tile.title} />
                </GridListTile>
              ))}
          </GridList>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <GridList cellHeight={cellHeight} cols={1} spacing={8}>
            {data
              .filter((image) => image.category === "heating_technology")
              .map((tile) => (
                <GridListTile rows={2} key={tile.id} cols={1}>
                  <img src={tile.img} alt={tile.title} />
                </GridListTile>
              ))}
          </GridList>
        </TabPanel>
      </div>
    </div>
  );
}

export default withWidth()(Gallery);
