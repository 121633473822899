import React from "react";
import Typography from "@material-ui/core/Typography";

import "./Privacy.css";

export default function Privacy() {
  return (
    <div className="privacy">
      <Typography variant="h2">Disclaimer / Datenschutz</Typography>
      <Typography variant="h5">Disclaimer – rechtliche Hinweise</Typography>
      <Typography variant="subtitle1">§ 1 Warnhinweis zu Inhalten</Typography>
      <Typography variant="subtitle1">
        Die kostenlosen und frei zugänglichen Inhalte dieser Webseite wurden mit
        größtmöglicher Sorgfalt erstellt. Der Anbieter dieser Webseite übernimmt
        jedoch keine Gewähr für die Richtigkeit und Aktualität der
        bereitgestellten kostenlosen und frei zugänglichen journalistischen
        Ratgeber und Nachrichten. Namentlich gekennzeichnete Beiträge geben die
        Meinung des jeweiligen Autors und nicht immer die Meinung des Anbieters
        wieder. Allein durch den Aufruf der kostenlosen und frei zugänglichen
        Inhalte kommt keinerlei Vertragsverhältnis zwischen dem Nutzer und dem
        Anbieter zustande, insoweit fehlt es am Rechtsbindungswillen des
        Anbieters.
      </Typography>
      <br />
      <Typography variant="subtitle1">§ 2 Externe Links</Typography>
      <Typography variant="subtitle1">
        Diese Website enthält Verknüpfungen zu Websites Dritter ("externe
        Links"). Diese Websites unterliegen der Haftung der jeweiligen
        Betreiber. Der Anbieter hat bei der erstmaligen Verknüpfung der externen
        Links die fremden Inhalte daraufhin überprüft, ob etwaige Rechtsverstöße
        bestehen. Zu dem Zeitpunkt waren keine Rechtsverstöße ersichtlich. Der
        Anbieter hat keinerlei Einfluss auf die aktuelle und zukünftige
        Gestaltung und auf die Inhalte der verknüpften Seiten. Das Setzen von
        externen Links bedeutet nicht, dass sich der Anbieter die hinter dem
        Verweis oder Link liegenden Inhalte zu Eigen macht. Eine ständige
        Kontrolle der externen Links ist für den Anbieter ohne konkrete Hinweise
        auf Rechtsverstöße nicht zumutbar. Bei Kenntnis von Rechtsverstößen
        werden jedoch derartige externe Links unverzüglich gelöscht.
      </Typography>
      <br />
      <Typography variant="subtitle1">
        § 3 Urheber- und Leistungsschutzrechte
      </Typography>
      <Typography variant="subtitle1">
        Die auf dieser Website veröffentlichten Inhalte unterliegen dem
        deutschen Urheber- und Leistungsschutzrecht. Jede vom deutschen Urheber-
        und Leistungsschutzrecht nicht zugelassene Verwertung bedarf der
        vorherigen schriftlichen Zustimmung des Anbieters oder jeweiligen
        Rechteinhabers. Dies gilt insbesondere für Vervielfältigung,
        Bearbeitung, Übersetzung, Einspeicherung, Verarbeitung bzw. Wiedergabe
        von Inhalten in Datenbanken oder anderen elektronischen Medien und
        Systemen. Inhalte und Rechte Dritter sind dabei als solche
        gekennzeichnet. Die unerlaubte Vervielfältigung oder Weitergabe
        einzelner Inhalte oder kompletter Seiten ist nicht gestattet und
        strafbar. Lediglich die Herstellung von Kopien und Downloads für den
        persönlichen, privaten und nicht kommerziellen Gebrauch ist erlaubt.
      </Typography>
      <Typography variant="subtitle1">
        Die Darstellung dieser Website in fremden Frames ist nur mit
        schriftlicher Erlaubnis zulässig.
      </Typography>
      <br />
      <Typography variant="subtitle1">
        § 4 Besondere Nutzungsbedingungen
      </Typography>
      <Typography variant="subtitle1">
        Soweit besondere Bedingungen für einzelne Nutzungen dieser Website von
        den vorgenannten Paragraphen abweichen, wird an entsprechender Stelle
        ausdrücklich darauf hingewiesen. In diesem Falle gelten im jeweiligen
        Einzelfall die besonderen Nutzungsbedingungen.
      </Typography>
      <Typography variant="subtitle1">
        Quelle:
        <a href="www.juraforum.de" rel="noopener noreferrer" target="_blank">
          www.juraforum.de
        </a>
      </Typography>
      <br />
      <br />
      <Typography variant="h5">Datenschutzerklärung</Typography>
      <Typography variant="h6">
        <b>Allgemeiner Hinweis und Pflichtinformationen</b>
      </Typography>
      <Typography variant="h6">
        <b>Benennung der verantwortlichen Stelle</b>
      </Typography>
      <Typography variant="subtitle1">
        Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website
        ist:
      </Typography>
      <Typography variant="subtitle1">Kiese-Service</Typography>
      <Typography variant="subtitle1">Andreas Kiese</Typography>
      <Typography variant="subtitle1">Rosenweg 5</Typography>
      <Typography variant="subtitle1">48336 Sassenberg</Typography>
      <Typography variant="subtitle1">
        Die verantwortliche Stelle entscheidet allein oder gemeinsam mit anderen
        über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten
        (z.B. Namen, Kontaktdaten o. Ä.).
      </Typography>
      <Typography variant="h6">
        <b>Widerruf Ihrer Einwilligung zur Datenverarbeitung</b>
      </Typography>
      <Typography variant="subtitle1">
        Nur mit Ihrer ausdrücklichen Einwilligung sind einige Vorgänge der
        Datenverarbeitung möglich. Ein Widerruf Ihrer bereits erteilten
        Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine
        formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum Widerruf
        erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
      </Typography>
      <Typography variant="h6">
        <b>Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde</b>
      </Typography>
      <Typography variant="subtitle1">
        Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen
        Verstoßes ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
        Zuständige Aufsichtsbehörde bezüglich datenschutzrechtlicher Fragen ist
        der Landesdatenschutzbeauftragte des Bundeslandes, in dem sich der Sitz
        unseres Unternehmens befindet. Der folgende Link stellt eine Liste der
        Datenschutzbeauftragten sowie deren Kontaktdaten bereit:
        <a
          href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
          rel="noopener noreferrer"
          target="_blank"
        >
          https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
        </a>
        .
      </Typography>
      <Typography variant="h6">
        <b>Recht auf Datenübertragbarkeit</b>
      </Typography>
      <Typography variant="subtitle1">
        Ihnen steht das Recht zu, Daten, die wir auf Grundlage Ihrer
        Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten,
        an sich oder an Dritte aushändigen zu lassen. Die Bereitstellung erfolgt
        in einem maschinenlesbaren Format. Sofern Sie die direkte Übertragung
        der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur,
        soweit es technisch machbar ist.
      </Typography>
      <Typography variant="h6">
        <b>Server-Log-Dateien</b>
      </Typography>
      <Typography variant="subtitle1">
        In Server-Log-Dateien erhebt und speichert der Provider der Website
        automatisch Informationen, die Ihr Browser automatisch an uns
        übermittelt. Dies sind:
      </Typography>
      <Typography variant="subtitle1">
        <li>Browsertyp und Browserversion</li>
        <li>Verwendetes Betriebssystem</li>
        <li>Referrer URL</li>
        <li>Hostname des zugreifenden Rechners</li>
        <li>Uhrzeit der Serveranfrage</li>
        <li>IP-Adresse</li>
      </Typography>
      <Typography variant="subtitle1">
        Es findet keine Zusammenführung dieser Daten mit anderen Datenquellen
        statt. Grundlage der Datenverarbeitung bildet Art. 6 Abs. 1 lit. b
        DSGVO, der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder
        vorvertraglicher Maßnahmen gestattet.
      </Typography>
      <br />
      <br />
      <Typography variant="subtitle2">
        {"Quelle: Datenschutz-Konfigurator von "}
        <a
          href="www.mein-datenschutzbeauftragter.de"
          rel="noopener noreferrer"
          target="_blank"
        >
          mein-datenschutzbeauftragter.de
        </a>
      </Typography>
    </div>
  );
}
